<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div class="fill-height fill-width">
      <v-app
        class="app"
      >
        <div
          v-if="!!user"
          class="fill-height fill-width d-flex flex-column"
        >
          <core-app-bar />

          <core-view>
            <template v-slot:map>
              <slot name="map" />
            </template>
          </core-view>
        </div>
        <o-loader v-else />
      </v-app>

      <o-confirm-dialog ref="confirm" />
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'DashboardIndex',

    components: {
      CoreView: () => import('@/views/admin/components/core/View'),
      CoreAppBar: () => import('./components/core/AppBar'),
    },

    computed: {
      ...mapState('auth', ['user']),
    },

    mounted () {
      this.$root.$confirm = this.$refs.confirm.open;
    },
  };
</script>

<style scoped lang="sass">
  .app
    position: relative
</style>
